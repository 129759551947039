/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Header from "./header"
import Brand from './brand'
import Helmet from 'react-helmet'
import GlobalStyle from '../theme/globalStyle.css'
import MEDIA from '../theme/mediaTemplates'
import {theme} from '../theme/theme'

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  main{
    width: 65%;
  }
  #brand{
    width: 35%;
    min-width: 300px;
    color: white;
    min-height: 100vh;
    position: relative;
    padding: ${theme.gridGutterDesktop};
    font-size: 1.1em;
    line-height: 1.2;
  }
  ${MEDIA.TABLET`
    #brand{
      padding: ${theme.gridGutterMobile};

    }
  `}
  ${MEDIA.PHONE`
    flex-direction: column;
    main{
      width: 100%;
    }
    #brand{
      width: 100%;
      padding: ${theme.gridGutterMobile};

    }
  `}
`
const Layout = ({ children, navItems }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Cabin&display=swap" rel="stylesheet"/>
      </Helmet>
      <GlobalStyle/>
      <LayoutContainer>
        <Header siteTitle={data.site.siteMetadata.title} navItems={navItems}/>
        <Brand/>
        <main id="main">
          {children}
          {/* <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer> */}
        </main>
      </LayoutContainer>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
