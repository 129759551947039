import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import {theme} from '../theme/theme'
import MEDIA from '../theme/mediaTemplates'

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]');
}

const HeaderContainer = styled.header`
  position: fixed;
  left: ${theme.gridGutterDesktop};
  top: ${theme.gridGutterDesktop};
  z-index: 99;
  a{
    padding: 0px 8px 8px 0px;
    font-size: 20px;
    text-transform: uppercase;
    margin-right: 14px;
    text-decoration: none;
    letter-spacing: 1px;
  }
  h1{
    margin-bottom: 0.2em;
    padding-bottom: 0.2em;
    font-size: 3.3em;
    border-bottom: 2px solid ${theme.colorPrimary};
  }
  ${MEDIA.TABLET`
     h1{
      font-size: 2em;

    }
    a{
      font-size: 16px;
      font-weight: 700;
    }
  `}
  ${MEDIA.PHONE`
    top: auto;
    bottom:0px;
    left:${theme.gridGutterMobile};
    display:block;
    background-color: white;
    width: 100%;
    h1{
      display: none;
    }
    a{
      font-size: 14px;
      font-weight: 700;
      padding: 10px 0px;
      display: inline-block;
    }
  `}
  ${MEDIA.MIN_GIANT`
    h1{
      font-size: 6em;

    }
    a{
      font-size: 36px;
    }

    `}

`
const Header = ({ siteTitle, navItems }) => (
  <HeaderContainer id="header">
    <h1>Clínica dental Miralles</h1>
    { navItems ? 
      navItems.map((item)=><a href={"#"+item.hook} key={item.title}>{item.title}</a>)
    : null }
  </HeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
