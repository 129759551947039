import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {theme} from '../theme/theme'
import MirallesLogo from '../images/svg/mirallesLogo.svg'
import MEDIA from '../theme/mediaTemplates'

const BrandContainer = styled.div`
    
    span,a{
        color: white;
        display: block;
        padding-left: 32px;
        ${MEDIA.TABLET`
            padding-left: ${theme.gridGutterMobile};

        `}
    }
    
    span{
        text-transform: uppercase;
        font-size: 0.6em;
        letter-spacing: 2px;
        padding-bottom: 0.5em;
    }
    a{
        margin-bottom: 1.3em;
        text-decoration: none;
    }
    .content{
        position: sticky;
        top: ${theme.gridGutterDesktop};
        width: 100%;
        min-height: 70vh;
        background-color: ${theme.colorPrimary};
        padding-bottom: 32px;
        display: flex;
        flex-direction: column;
        align-content: space-between;
        /* align-items: space-between; */
        ${MEDIA.PHONE`
            top: ${theme.gridGutterMobile};
            padding-bottom: ${theme.gridGutterMobile};
        `}
        ${MEDIA.MIN_GIANT`
            span{
                font-size: 1em;
            }
            a{
                font-size: 1.5em;
            }
        `}


        ${MEDIA.MIN_PHONE`
            #info{
                position: absolute;
                bottom: 0px;
            }
        `}
    }
`
const Brand = () => {
    const [viewHeight, setViewHeight] = useState(0);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        // document.window = `You clicked ${count} times`;
        var windowInnerHeight = window.innerHeight-120;
        setViewHeight (windowInnerHeight);

    });

    const contentStyle = {
        minHeight: viewHeight,
        }
    return (
        <BrandContainer id="brand">
            <div className="content" style={contentStyle}>
                <MirallesLogo/>
                <div id="info">
                    <span className="overline">Teléfono:</span>
                    <a href="phone:965447950">965 447 950</a>
                    <span className="overline">Email:</span>
                    <a href="mailto:dentalmiralles@gmail.com">dentalmiralles@gmail.com</a>
                    <span className="overline">Whatsapp:</span>
                    <a href="https://wa.me/34667548543">667 548 543</a>
                    <span className="overline">Dirección:</span>
                    <a href="https://goo.gl/maps/MGHkU47trndwyyrq5">Calle Cristóbal Sanz 31, Bajo <br/>03201 Elche, Alicante</a>
                    
                </div>

            </div>

        </BrandContainer>
    )
}
  
  Brand.propTypes = {
    // siteTitle: PropTypes.string,
  }
  
  Brand.defaultProps = {
    // siteTitle: ``,
  }
  
  export default Brand
  