const theme = {
    colorPrimary: "#203A4F",
    gridGutterDesktop: '64px',
    gridGutterMobile: '24px',
}

const BREACKPOINTS = {
    GIANT: 1800,
    DESKTOP: 992,
    TABLET: 910,
    PHONE: 670,
}


export {theme, BREACKPOINTS }
  